import { useEffect, useState } from 'react'
import SEO from '../Components/SEO';
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material';
import ArticleList from '../Components/Organisms/ArticleList'
import Page404 from '../Pages/Page404';
import data from '../data.json'

function CategoryTemplate() {
    const { categoryId } = useParams();
    const [valid, setValid] = useState(false);
    const [category, setCategory] = useState({});

    useEffect(() => {
        data.categories.forEach(c => {
            if (c.id.toLowerCase() === categoryId.toLowerCase()) {
                setValid(true);
                setCategory(c)
            }
        });
    }, [categoryId])

    return (
        valid ? <>
            <SEO
                title={`Wypożyczalnia bumerang - ${category.title}`}
                description={category.shortDescription}
            />
            <Typography variant="h1" color='primary'>{category.title}</Typography>
            <Typography variant="body1" >{category.description}</Typography>
            <ArticleList category={categoryId} />
        </> : <Page404 whatIsWrong={'category'} />
    )
}

export default CategoryTemplate