import { useRef, useEffect } from 'react'
import './searchBox.scss'
import { Grid, Typography, Backdrop, Box, Card, CardMedia, CardContent } from '@mui/material';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Link, useNavigate } from 'react-router-dom';

import data from '../../data.json'



const SearchBox = ({ open, handleClose }) => {
    const navigate = useNavigate();

    const items = data.equipment







    // const handleOnSearch = (string, results) => {
    //     // onSearch will have as the first callback parameter
    //     // the string searched and for the second the results.
    //     console.log(string, results)
    // }

    // const handleOnHover = (result) => {
    //     // the item hovered
    //     console.log(result)
    // }

    const handleOnSelect = (item) => {
        // the item selected
        navigate(`/oferta/${item.category}/${item.id}`)
        handleClose()
        // console.log(item)
    }

    // const handleOnFocus = () => {
    //     console.log('Focused')
    // }

    const formatResult = (item) => {
        return (
            <>
                <Grid container className='search-result'>
                    <Grid item xs={2} className={'search-result__media'}>
                        <img src={item.images[0].thumbnail}
                            className={'search-result__mediaImage'}
                            alt={item.name}
                            title={item.name} />
                    </Grid>
                    <Grid item xs={10} className={'search-result__text'}>
                        <Typography
                            variant="h4">
                            {item.name}
                        </Typography>
                        {
                            item.description ?
                                <Typography
                                    variant="body1"
                                    color={'GrayText'}>
                                    {item.shortDescription}
                                </Typography> : null
                        }
                    </Grid>
                    {/* <Grid item xs={10}>
                    </Grid> */}
                    {/* <Grid item>

                    </Grid> */}
                </Grid>
                {/* <Card
                    elevation={2}
                    className={'articleTile'}
                >
                    <div className={'articleTile__media'}>
                        <img src={item.mainImage}
                            className={'articleTile__mediaImage'}
                            alt={item.name}
                            title={item.name} />
                    </div> 
                    <CardContent className={'articleTile__content'}>
                        <Typography
                            variant="h4">
                            {item.name}
                        </Typography> 
                {
                    item.description ?
                        <Typography
                            variant="body1"
                            color="textSecondary">
                            {item.shortDescription}
                        </Typography> : null
                }

            </CardContent >
                </Card > * /}
{/* <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span> */ }
            </>
        )
    }


    return (
        <Backdrop
            sx={{
                background: '#000000cc', zIndex: (theme) => theme.zIndex.drawer + 1,
                overflowY: 'auto !important'
            }}
            open={open}
            onClick={handleClose}
        >
            <Box
                className='search-box'
                // sx={{ display: { xs: 'none', sm: 'flex' } }}
                // style={{ paddingRight: { xs: '40px', sm: '0' }, width: "100%" }}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                onClick={e => e.stopPropagation()}>
                <ReactSearchAutocomplete
                    items={items}
                    // onSearch={handleOnSearch}
                    // onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    // onFocus={handleOnFocus}
                    autoFocus
                    formatResult={formatResult}
                    maxResults={15}
                    showIcon={false}
                    showItemsOnFocus
                    placeholder="Znajdź odpowiedni sprzęt..."
                    showNoResultsText="Nie mamy czegoś takiego..."
                    fuseOptions={{ keys: ["name", "description", 'shortDescription', 'category'], threshold: 0.2 }} //benefits jako array do sprawdzenia
                />
            </Box>
        </Backdrop>
    )
}

export default SearchBox