import React from 'react'
import SEO from '../Components/SEO';

import CategoryTiles from '../Components/CategoryTiles'

const Offer = () => {
    return (
        <>
            <SEO
                title="Wypożyczalnia bumerang - Oferta"
                description="Sprawdź ofertą. Wybierz jedną z kategorii sprzętu jakim dysponujemy i zapoznaj się ze specyfikacją tego czego szukasz."
            />
            <CategoryTiles />
        </>
    )
}

export default Offer