import React from 'react'
import SEO from '../Components/SEO';
const Page404 = ({ whatIsWrong = '' }) => {
    return (
        <>
            <SEO
                title="Wypożyczalnia bumerang - 404 - Nie ma takiej strony"
                description="Niczego tutaj nie ma ;( Adres strony, który wpisałeś nie istnieje. Sprawdź pisownię lub przejdź na stronę główną."
            />
            <h1>Błąd 404</h1>
            <p>Nieprawidłowy adres strony...</p>
            {whatIsWrong === 'category' ? <p>Błędna nazwa kategorii</p> : null}
            {whatIsWrong === 'article' ? <p>Błędna nazwa artykułu</p> : null}
        </>
    )
}

export default Page404